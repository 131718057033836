<template>
  <div
    class="chat-form px-1"
    :class="{ 'chat-form--bottom': bottom }"
    :style="{ 'min-width': parentWidth + 'px' }"
    @submit="sendMessage"
    @submit.prevent
  >
    <v-form class="form split-form">
      <div class="form__data">
        <v-emoji-picker class="emiji" v-if="emojisVisible" @select="selectEmoji" />

        <div
          role="textbox"
          class="form-data__input"
          ref="currentMessage"
          v-html="currentMessage"
          @input="onMessageChange($event.target.innerText)"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="sendMessage"
          contenteditable
          :data-placeholder="$t('chat.message')"
          data-test-id="textarea"
          aria-multiline="true"
        />
        <v-btn
          class="form__controls mr-0"
          text
          color="primaryFont"
          @click.native="toggleEmoji"
          data-test-id="emoji-btn"
        >
          <v-icon color="gray">mdi-emoticon-happy</v-icon>
        </v-btn>

        <v-btn
          class="form__controls"
          text
          color="gray"
          @click.native="sendMessage"
          data-test-id="send-btn"
          >Send
          <v-icon class="ml-2">mdi-send</v-icon>
        </v-btn>
      </div>
    </v-form>
    <div
      ref="chat"
      v-if="messages"
      class="chat"
      :v-chat-scroll="{ always: false, enabled: bottom, smooth: true, notSmoothOnInit: true }"
    >
      <div
        :id="message.id"
        v-for="message in messages"
        :key="message.id"
        class="chat__message"
        :class="{ 'chat__message--moderated': message.isModerated }"
        @mouseleave="hoveredItem = null"
        @mouseenter="hoveredItem = message"
      >
        <div class="message__picture" :class="{ 'message__picture--hovered': isHovered(message) }">
          <user-avatar
            :avatar="message.attributes && message.attributes.avatar"
            :thinBorder="true"
          />
        </div>

        <div class="message__content" :class="{ 'message__content--hovered': isHovered(message) }">
          <div class="content__header">
            {{ message.attributes.firstName }} {{ message.attributes.lastName }}
            <span class="content__header--muted">
              {{ formatLocalTimeWithSeconds(message.created) }}
            </span>
          </div>
          <div class="content__text">{{ message.msg }}</div>
        </div>

        <div class="message__action" v-if="canModerate && isHovered(message)">
          <v-btn color="primaryFont" @click="toggleModerationStatus(message)">
            {{ moderatedText(message) }}
          </v-btn>
        </div>
      </div>
      <Observer @intersect="intersected" />
      <v-divider v-if="bottom" />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { VEmojiPicker } from 'v-emoji-picker';

import DateHelper from '@/helpers/date/date.helper';

import UserAvatar from '@/components/user-avatar/UserAvatar.vue';
import Observer from '@/components/observer/Observer.vue';

export default {
  name: 'Chat',
  props: ['user', 'messages', 'bottom', 'hideAvatar', 'messagesAreLoading', 'canModerate'],
  components: {
    UserAvatar,
    Observer,
    VEmojiPicker,
  },
  data: () => ({
    currentMessage: '',
    message: '',
    messageBefore: '',
    hoveredItem: null,
    emojisVisible: false,
    parentWidth: 240,
  }),
  computed: {
    moderatedText() {
      return (message) =>
        message.isModerated ? this.$t('chat.display') : this.$t('chat.moderate');
    },
  },
  mounted() {
    if (document.getElementById('conference-parent-chat')?.offsetWidth)
      this.parentWidth = document.getElementById('conference-parent-chat')?.offsetWidth;
  },
  methods: {
    addNewLine(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = `${event.target.value}\n`;
    },
    formatLocalTimeWithSeconds(datetime) {
      return DateHelper.formatLocalTimeWithSeconds(DateTime.fromISO(datetime), this.$i18n.locale);
    },
    selectEmoji(emoji) {
      this.message = `${this.message}${emoji.data}`;
      this.currentMessage = this.message;
    },
    toggleEmoji() {
      this.emojisVisible = !this.emojisVisible;
    },
    onMessageChange(params) {
      const message = params;
      this.messageBefore = message;
      this.message = message;
      this.tagsVisible = false;

      if (
        (message.charAt(message.length - 2) === '' || message.charAt(message.length - 2) === ' ') &&
        message.charAt(message.length - 1) === '@'
      ) {
        this.tagsVisible = true;
      }
    },
    sendMessage() {
      const currentMessage = this.message;
      if (currentMessage !== '') {
        this.$emit(
          'send-message',
          currentMessage
            .replace(/&nbsp;/g, ' ')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&'),
        );
        this.currentMessage = '';
      }
      this.$refs.currentMessage.innerHTML = '';
      this.message = '';
      this.messageBefore = '';
    },
    intersected() {
      this.$emit('load-more');
    },
    toggleModerationStatus(message) {
      this.$emit('moderate-message', {
        ...message,
        isModerated: !message.isModerated,
      });
    },
    isHovered(message) {
      return this?.hoveredItem?.id === message.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

.split-form {
  border-top: 1px solid gray;
}

.chat-form {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 100%;

  &--bottom {
    flex-direction: column-reverse;
  }
}

#chat {
  display: flex;
  flex-direction: column;
}

.chat {
  overflow: auto;
  height: 80vh;
  display: flex;
  flex-direction: column-reverse;

  &:first-child {
    margin-top: auto;
  }

  &__message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.5rem;
    width: 100%;

    &--moderated {
      background: repeating-linear-gradient(
        45deg,
        rgb(40, 40, 40),
        rgb(40, 40, 40) 5px,
        rgb(60, 60, 60) 5px,
        rgb(60, 60, 60) 10px
      );
    }

    &--hovered {
      background: rgba(30, 30, 30, 0.5);
    }
  }

  &__clickable {
    cursor: pointer;
  }
}

.form {
  flex: 0 0;
  padding: 0.5rem;
  position: relative;

  #emoji-btn {
    span {
      color: gray !important;
    }

    .v-btn__content {
      color: gray !important;
    }
  }

  &__data {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__controls {
    text-align: right;
  }

  &__controls.v-btn {
    min-width: 35px;
  }
}

.form-data {
  &__picture {
    width: auto;
    margin-right: 5px;
  }

  &__input {
    flex-grow: 3;
    flex: 3;
    font-size: 0.75rem;
    margin: 0 0 0 0.5rem;
    padding: 0;
    border-bottom: 1px solid var(--v-primaryFont-base);
    max-height: 100px;
    overflow-y: scroll;

    &:focus {
      outline: none !important;
    }
  }
}

.message {
  position: relative;

  &__picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0.5rem;

    &--hovered {
      opacity: 0.5;
    }
  }

  &__content {
    flex: 4;

    &--hovered {
      opacity: 0.5;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.picture {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
}

.content {
  &__header {
    display: flex;
    align-items: baseline;
    font-size: $small-font-size;
    font-weight: $bold;

    &--muted {
      margin-left: 8px;
      font-weight: $regular;
      font-size: $x-small-font-size;
      color: var(--v-gray-base);
      white-space: no-wrap;
      word-spacing: -0.1rem;
    }
  }

  &__text {
    white-space: pre-wrap;
    font-size: $small-font-size;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}

.header {
  &__icon {
    color: var(--v-primaryFont-base);
    padding-right: 0.5rem;
  }
}

@include breakpoint(medium) {
  #chat {
    font-size: $small-font-size;
  }

  .chat {
    flex: 1 1 0;
  }
}

[data-placeholder]:empty::before {
  content: attr(data-placeholder);
  color: var(--v-gray-base);
}

.emoji-picker {
  position: absolute;
  bottom: 70px;
  width: 100% !important;
  z-index: 2;
}
</style>
